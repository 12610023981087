import englishMessages from 'ra-language-english';

export const en = {
  ...englishMessages,
  par: {
    shared: {
      status: {
        active: 'ACTIVE',
        inactive: 'INACTIVE',
      },
    }
  },
};