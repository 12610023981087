import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  ReferenceField,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import IsActiveField from "../Common/IsActiveField";

export const ConceptShow = (props:any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="detail">
        <TextField source="name" />
        <TextField source="description" />
        <DateField source="createdAt" label="Created At" showTime />
        <ReferenceField
          source="createdBy"
          reference="users"
          label="Created By"
          link="show"
        />
        <DateField source="modifiedAt" label="Modified At" showTime />
        <ReferenceField
          source="modifiedBy"
          reference="users"
          label="Modified By"
          link="show"
        />
        <IsActiveField
          source="isActive"
          label="Active?"
        />
        <TextField source="id" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="users">
        <ReferenceManyField reference="users" target="conceptId" label={false}>
          <Datagrid>
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name" />
            <EmailField source="email" />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Access Areas">
        <ReferenceManyField
          reference="accessAreas"
          target="conceptId"
          label={false}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="description" />
            <BooleanField
              source="enabled"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Tenants">
        <ReferenceManyField
          reference="tenants"
          target="conceptId"
          label={false}
        >
          <Datagrid>
            <TextField source="value" />
            <ReferenceField
              source="tenantTypeId"
              label="Tenant Type"
              reference="tenantTypes"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField source="description" />
            <BooleanField
              source="enabled"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
