import {
  BooleanInput,
  DateInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const AccessAreaCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="tenantTypeId" reference="tenantTypes" />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);
