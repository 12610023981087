/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTenantCommand } from '../models/CreateTenantCommand';
import type { ListQueryResultOfTenantDto } from '../models/ListQueryResultOfTenantDto';
import type { SortDirection } from '../models/SortDirection';
import type { TenantDto } from '../models/TenantDto';
import type { UpdateTenantCommand } from '../models/UpdateTenantCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TenantService {

    /**
     * @param id 
     * @returns TenantDto 
     * @throws ApiError
     */
    public static tenantGet(
id: string,
): CancelablePromise<TenantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Tenant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static tenantUpdate(
id: string,
requestBody: UpdateTenantCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Tenant/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static tenantDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Tenant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param sortField 
     * @param sortDirection 
     * @param filter 
     * @param activeFilter 
     * @returns ListQueryResultOfTenantDto 
     * @throws ApiError
     */
    public static tenantList(
pageNumber?: number,
pageSize?: number,
sortField?: string,
sortDirection?: SortDirection,
filter?: string | null,
activeFilter?: boolean | null,
): CancelablePromise<ListQueryResultOfTenantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Tenant',
            query: {
                'Page.Number': pageNumber,
                'Page.Size': pageSize,
                'Sort.Field': sortField,
                'Sort.Direction': sortDirection,
                'Filter': filter,
                'ActiveFilter': activeFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string 
     * @throws ApiError
     */
    public static tenantCreate(
requestBody: CreateTenantCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Tenant',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
