import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import IsActiveField from "../Common/IsActiveField";

export const PermissionShow = (props:any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="accessAreaId"
        reference="accessAreas"
        link="show"
      />
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" label="Created At" showTime />
      <ReferenceField
        source="createdBy"
        reference="users"
        label="Created By"
        link="show"
      />
      <DateField source="modifiedAt" label="Modified At" showTime />
      <ReferenceField
        source="modifiedBy"
        reference="users"
        label="Modified By"
        link="show"
      />
      <IsActiveField
        source="isActive"
        label="Active?"
      />
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);
