import {
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const TenantCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="tenantTypeId" reference="tenantTypes" />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);
