import { SortDirection } from "../generated";

export class ServiceHelper {
  static getSortDirection(params: any): SortDirection {
    return params.sort.order == "ASC" ? 0 : 1;
  }

  static getActiveFilter(params: any): boolean | null {
    return params.filter.includeInactive === true ? null : true;
  }
}