import {
  CreateResult,
  DataProvider,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetManyResult,
  GetOneResult,
  UpdateManyResult,
  UpdateResult,
} from "react-admin";
import {TenantTypeService} from "../generated";
import {withToken} from "../TokenHelper";
import {ServiceHelper} from "../Common/ServiceHelpers";

export default (): DataProvider => ({
  getList: (resource, params) =>
    withToken(async () => {
      return TenantTypeService.tenantTypeList(
        params.pagination.page,
        params.pagination.perPage,
        params.sort.field,
        ServiceHelper.getSortDirection(params),
        params.filter.q,
        ServiceHelper.getActiveFilter(params)
      ).then((response) => {
        return {
          data: response.results,
          pageInfo: {
            hasNextPage: response.pageInfo.hasNextPage,
            hasPreviousPage: response.pageInfo.hasPreviousPage,
          },
        } as GetListResult;
      });
    }),

  getOne: (resource, params) =>
    withToken(async () => {
      return TenantTypeService.tenantTypeGet(params.id.toString()).then(
        (response) => {
          return {data: response} as GetOneResult;
        }
      );
    }),

  getMany: (resource, params) =>
    withToken(async () => {
      return TenantTypeService.tenantTypeGetMany(params.ids.map(String)).then((response) => {
        return {
          data: response,
        } as GetManyResult;
      });
    }),

  getManyReference: (resource, params) =>
    withToken(async () => {
      return TenantTypeService.tenantTypeList(
        params.pagination.page,
        params.pagination.perPage,
        params.sort.field,
        ServiceHelper.getSortDirection(params),
        params.filter
      ).then((response) => {
        return {
          data: response.results,
          pageInfo: {
            hasNextPage: response.pageInfo.hasNextPage,
            hasPreviousPage: response.pageInfo.hasPreviousPage,
          },
        } as GetListResult;
      });
    }),

  update: (resource, params) =>
    withToken(async () => {
      const command = {
        id: params.id.toString(),
        name: params.data.name,
        description: params.data.description,
      };
      return TenantTypeService.tenantTypeUpdate(
        params.id.toString(),
        command
      ).then((response) => {
        return TenantTypeService.tenantTypeGet(params.id.toString()).then(
          (response) => {
            return {data: response} as UpdateResult;
          }
        );
      });
    }),

  updateMany: (resource, params) =>
    withToken(async () => {
      return Promise.resolve({ data: [] }) as Promise<UpdateManyResult>;
    }),

  create: (resource, params) =>
    withToken(async () => {
      const command = {
        name: params.data.name,
        description: params.data.description,
      };
      return TenantTypeService.tenantTypeCreate(command).then((response) => {
        return TenantTypeService.tenantTypeGet(response).then((getResponse) => {
          return {data: getResponse} as CreateResult;
        });
      });
    }),

  delete: (resource, params) =>
    withToken(async () => {
      return TenantTypeService.tenantTypeDelete(params.id.toString()).then(
        (response) => {
          return {data: response} as DeleteResult;
        }
      );
    }),

  deleteMany: (resource, params) =>
    withToken(async () => {
      const deletePromises = params.ids.map((id) =>
        TenantTypeService.tenantTypeDelete(id.toString())
      );
      return Promise.all(deletePromises).then((responses) => {
        return {
          data: responses.map((response) => response),
        } as DeleteManyResult;
      });
    }),
});
