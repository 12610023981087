/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConceptDto } from '../models/ConceptDto';
import type { CreateConceptCommand } from '../models/CreateConceptCommand';
import type { ListQueryResultOfConceptDto } from '../models/ListQueryResultOfConceptDto';
import type { SortDirection } from '../models/SortDirection';
import type { UpdateConceptCommand } from '../models/UpdateConceptCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConceptsService {

    /**
     * @param id 
     * @returns ConceptDto 
     * @throws ApiError
     */
    public static conceptsGet(
id: string,
): CancelablePromise<ConceptDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Concepts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static conceptsUpdate(
id: string,
requestBody: UpdateConceptCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Concepts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static conceptsDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Concepts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param sortField 
     * @param sortDirection 
     * @param filter 
     * @param activeFilter 
     * @returns ListQueryResultOfConceptDto 
     * @throws ApiError
     */
    public static conceptsList(
pageNumber?: number,
pageSize?: number,
sortField?: string,
sortDirection?: SortDirection,
filter?: string | null,
activeFilter?: boolean | null,
): CancelablePromise<ListQueryResultOfConceptDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Concepts',
            query: {
                'Page.Number': pageNumber,
                'Page.Size': pageSize,
                'Sort.Field': sortField,
                'Sort.Direction': sortDirection,
                'Filter': filter,
                'ActiveFilter': activeFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string 
     * @throws ApiError
     */
    public static conceptsCreate(
requestBody: CreateConceptCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Concepts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns ConceptDto 
     * @throws ApiError
     */
    public static conceptsGetMany(
ids?: Array<string>,
): CancelablePromise<Array<ConceptDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Concepts/GetMany',
            query: {
                'Ids': ids,
            },
        });
    }

}
