import { Create, ReferenceInput, SimpleForm, TextInput } from "react-admin";

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <TextInput source="username" />
      <ReferenceInput source="conceptId" reference="concepts" />
    </SimpleForm>
  </Create>
);
