import { useTranslate } from "react-admin";
import { Chip } from "@mui/material";

type QuickFilterProps = {
  label: string;
  defaultValue: boolean;
  source: string; // add this line
};

const QuickFilter: React.FC<QuickFilterProps> = ({
  label,
  defaultValue,
  source,
}) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

export default QuickFilter;
