import {
  DataProvider,
  GetListResult,
  GetOneResult,
  UpdateResult,
  UpdateManyResult,
  CreateResult,
  DeleteResult,
  DeleteManyResult,
  GetManyResult,
} from "react-admin";
import {UserService, CreateUserCommand, SortDirection} from "../generated";
import { withToken } from "../TokenHelper";
import { ServiceHelper } from "../Common/ServiceHelpers";

export default (): DataProvider => ({
  getList: (resource, params) =>
    withToken(async () => {
      return UserService.userList(
        params.pagination.page,
        params.pagination.perPage,
        params.sort.field,
        ServiceHelper.getSortDirection(params),
        params.filter.q,
        ServiceHelper.getActiveFilter(params)
      ).then((response) => {
        return {
          data: response.results,
          pageInfo: {
            hasNextPage: response.pageInfo.hasNextPage,
            hasPreviousPage: response.pageInfo.hasPreviousPage,
          },
        } as GetListResult<any>;
      });
    }),

  getOne: (resource, params) =>
    withToken(async () => {
      return UserService.userGet(params.id.toString()).then((response) => {
        return { data: response } as GetOneResult<any>;
      });
    }),

  getMany: (resource, params) =>
    withToken(async () => {
      return UserService.userGetMany(params.ids.map(String)).then((response) => {
        return {
          data: response,
        } as GetManyResult;
      });
    }),

  getManyReference: (resource, params) =>
    withToken(async () => {
      if (process.env.NODE_ENV === "development") {
        console.log("getManyReference", resource, params);
      }

      const sortField = params.sort.field;
      const sortDirection : SortDirection = params.sort.order == "ASC" ? 0 : 1;

      return UserService.userList(
        params.pagination.page,
        params.pagination.perPage,
        sortField,
        sortDirection,
        params.filter
      ).then((response) => {
        const hasNextPage = response.pageInfo.hasNextPage;
        const hasPreviousPage = response.pageInfo.hasPreviousPage;

        const result = {
          data: response.results,
          pageInfo: {
            hasNextPage: hasNextPage,
            hasPreviousPage: hasPreviousPage,
          },
        } as GetListResult<any>;

        if (process.env.NODE_ENV === "development") {
          console.log("getManyReference", resource, "result", result);
        }

        return result;
      });
    }),

  update: (resource, params) =>
    withToken(async () => {
      if (process.env.NODE_ENV === "development") {
        console.log("update", resource, params);
      }

      const command = {
        id: params.id.toString(),
        name: params.data.name,
        description: params.data.description,
      };
      return UserService.userUpdate(params.id.toString(), command).then(
        (response) => {
          return UserService.userGet(params.id.toString()).then((response) => {
            const result = { data: response } as UpdateResult<any>;

            if (process.env.NODE_ENV === "development") {
              console.log("update", resource, "result", result);
            }

            return result;
          });
        }
      );
    }),

  updateMany: (resource, params) =>
    withToken(async () => {
      if (process.env.NODE_ENV === "development") {
        console.log("updateMany", resource, params);
      }

      return Promise.resolve({ data: [] }) as Promise<UpdateManyResult<any>>;
    }),

  create: (resource, params) =>
    withToken(async () => {
      if (process.env.NODE_ENV === "development") {
        console.log("create", resource, params);
      }
      let command: CreateUserCommand = {
        firstName: params.data.firstName,
        lastName: params.data.lastName,
        email: params.data.email,
        username: params.data.username,
        conceptId: params.data.conceptId,
      };
      return UserService.userCreate(command).then((response) => {
        return UserService.userGet(response).then((getResponse) => {
          const result = { data: getResponse } as CreateResult<any>;

          if (process.env.NODE_ENV === "development") {
            console.log("create", resource, "result", result);
          }

          return result;
        });
      });
    }),

  delete: (resource, params) =>
    withToken(async () => {
      if (process.env.NODE_ENV === "development") {
        console.log("delete", resource, params);
      }
      return UserService.userDelete(params.id.toString()).then((response) => {
        const result = { data: response } as DeleteResult<any>;

        if (process.env.NODE_ENV === "development") {
          console.log("delete", resource, "result", result);
        }

        return result;
      });
    }),

  deleteMany: (resource, params) =>
    withToken(async () => {
      if (process.env.NODE_ENV === "development") {
        console.log("deleteMany", resource, params);
      }

      const deletePromises = params.ids.map((id) =>
        UserService.userDelete(id.toString())
      );
      return Promise.all(deletePromises).then((responses) => {
        const result = {
          data: responses.map((response) => response),
        } as DeleteManyResult<any>;

        if (process.env.NODE_ENV === "development") {
          console.log("deleteMany", resource, "result", result);
        }

        return result;
      });
    }),
});
