import { BooleanInput, DateInput, Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export const AccessAreaEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="tenantTypeId" reference="tenantTypes" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);