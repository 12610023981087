import { Chip } from '@mui/material';
import { BooleanFieldProps } from 'ra-ui-materialui';
import CircleIcon from '@mui/icons-material/Circle';
import { useRecordContext, useTranslate } from 'react-admin';

const IsActiveField: React.FC<BooleanFieldProps> = (props) => {
    const isActive = useRecordContext(props).isActive;
    const translate = useTranslate();

    return (
        <Chip
            className="isActiveChip"
            size="small"
            icon={<CircleIcon style={{
                color: isActive ? "green" : "red", fontSize: "11px",
            }} />}
            label={isActive
              ? translate('par.shared.status.active')
              : translate('par.shared.status.inactive')}
        />
    );
};

export default IsActiveField;