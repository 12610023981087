import { Edit, SimpleForm, TextInput } from "react-admin";

export const ConceptEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="address" />
    </SimpleForm>
  </Edit>
);
