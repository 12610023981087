import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  ReferenceField,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import IsActiveField from "../Common/IsActiveField";

export const UserShow = (props:any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="detail">
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <TextField source="username" />
        <EmailField source="email" />
        <ReferenceField source="conceptId" reference="concepts" />
        <DateField source="createdAt" label="Created At" showTime />
        <ReferenceField
          source="createdBy"
          reference="users"
          label="Created By"
          link="show"
        />
        <DateField source="modifiedAt" label="Modified At" showTime />
        <ReferenceField
          source="modifiedBy"
          reference="users"
          label="Modified By"
          link="show"
        />
        <IsActiveField
          source="isActive"
          label="Active?"
        />
        <TextField source="id" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="User Groups">
        <ReferenceManyField
          label=""
          reference="userGroups"
          target="userGroupId"
        >
          <Datagrid size="medium">
            <TextField source="name" />
            <ReferenceField
              source="conceptId"
              reference="concepts"
              link="show"
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Tenants">
        <ReferenceManyField reference="tenants" target="userId" label={false}>
          <Datagrid>
            <TextField source="value" />
            <ReferenceField
              source="tenantTypeId"
              label="Tenant Type"
              reference="tenantTypes"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField source="description" />
            <BooleanField
              source="enabled"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Par Admin Tools">
        <ReferenceManyField
          label=""
          reference="permissions"
          target="userId"
          filter={{ accessAreaId: 1 }}
        >
          <Datagrid size="medium">
            <TextField source="name" />
            <BooleanField
              source="isUserAllowed"
              label="Is Allowed"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Admin Tools">
        <ReferenceManyField
          label=""
          reference="permissions"
          target="userId"
          filter={{ accessAreaId: 2 }}
        >
          <Datagrid size="medium">
            <TextField source="name" />
            <BooleanField
              source="isUserAllowed"
              label="Is Allowed"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Merchant Tools">
        <ReferenceManyField
          label=""
          reference="permissions"
          target="userId"
          filter={{ accessAreaId: 3 }}
        >
          <Datagrid size="medium">
            <TextField source="name" />
            <BooleanField
              source="isUserAllowed"
              label="Is Allowed"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Support Tools">
        <ReferenceManyField
          label=""
          reference="permissions"
          target="userId"
          filter={{ accessAreaId: 4 }}
        >
          <Datagrid size="medium">
            <TextField source="name" />
            <BooleanField
              source="isUserAllowed"
              label="Is Allowed"
              FalseIcon={null}
              sx={{ color: "green" }}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
