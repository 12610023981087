/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePermissionCommand } from '../models/CreatePermissionCommand';
import type { ListQueryResultOfPermissionDto } from '../models/ListQueryResultOfPermissionDto';
import type { PermissionDto } from '../models/PermissionDto';
import type { SortDirection } from '../models/SortDirection';
import type { UpdatePermissionCommand } from '../models/UpdatePermissionCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PermissionService {

    /**
     * @param id 
     * @returns PermissionDto 
     * @throws ApiError
     */
    public static permissionGet(
id: string,
): CancelablePromise<PermissionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Permission/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static permissionUpdate(
id: string,
requestBody: UpdatePermissionCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Permission/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static permissionDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Permission/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param sortField 
     * @param sortDirection 
     * @param filter 
     * @param activeFilter 
     * @returns ListQueryResultOfPermissionDto 
     * @throws ApiError
     */
    public static permissionList(
pageNumber?: number,
pageSize?: number,
sortField?: string,
sortDirection?: SortDirection,
filter?: string | null,
activeFilter?: boolean | null,
): CancelablePromise<ListQueryResultOfPermissionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Permission',
            query: {
                'Page.Number': pageNumber,
                'Page.Size': pageSize,
                'Sort.Field': sortField,
                'Sort.Direction': sortDirection,
                'Filter': filter,
                'ActiveFilter': activeFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string 
     * @throws ApiError
     */
    public static permissionCreate(
requestBody: CreatePermissionCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Permission',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
