import {
  BooleanInput,
  DateInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const PermissionCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="accessAreaId" reference="accessAreas" />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);
