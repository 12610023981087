import {
  Datagrid,
  DateField,
  EmailField,
  InfiniteList,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";
import IsActiveField from "../Common/IsActiveField";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source={"conceptId"} reference={"concepts"} />,
];

export const UserList = () => (
  <InfiniteList
      filters={filters}
      sort={{ field: 'firstName', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="firstName" label="First Name" />
      <TextField source="lastName" label="Last Name" />
      <TextField source="username" />
      <EmailField source="email" />
      <ReferenceField source="conceptId" reference="concepts" />
      <DateField source="createdAt" label="Created At" />
      <ReferenceField
        source="createdBy"
        label="Created By"
        reference="users"
        link="show"
      />
      <DateField source="modifiedAt" label="Modified At" />
      <ReferenceField
        source="modifiedBy"
        label="Modified By"
        reference="users"
        link="show"
      />
      <IsActiveField
        source="isActive"
        label="Active?"
      />
    </Datagrid>    
  </InfiniteList>
);
