import {
  CreateResult,
  DataProvider,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  UpdateManyResult,
  UpdateResult,
} from "react-admin";
import {TenantService} from "../generated";
import {withToken} from "../TokenHelper";
import {ServiceHelper} from "../Common/ServiceHelpers";

export default (): DataProvider => ({
  getList: (resource, params) =>
    withToken(async () => {
      return TenantService.tenantList(
        params.pagination.page,
        params.pagination.perPage,
        params.sort.field,
        ServiceHelper.getSortDirection(params),
        params.filter.q,
        ServiceHelper.getActiveFilter(params)
      ).then((response) => {
        return {
          data: response.results,
          pageInfo: {
            hasNextPage: response.pageInfo.hasNextPage,
            hasPreviousPage: response.pageInfo.hasPreviousPage,
          },
        } as GetListResult;
      });
    }),

  getOne: (resource, params) =>
    withToken(async () => {
      return TenantService.tenantGet(params.id.toString()).then((response) => {
        return {data: response} as GetOneResult;
      });
    }),

  getMany: (resource, params) =>
    withToken(async () => {
      return TenantService.tenantList().then((response) => {
        return {
          data: response.results,
          pageInfo: {
            hasNextPage: response.pageInfo.hasNextPage,
            hasPreviousPage: response.pageInfo.hasPreviousPage,
          },
        } as GetListResult;
      });
    }),

  getManyReference: (resource, params) =>
    withToken(async () => {
      return TenantService.tenantList(
        params.pagination.page,
        params.pagination.perPage,
        params.sort.field,
        ServiceHelper.getSortDirection(params),
        params.filter.q,
        ServiceHelper.getActiveFilter(params)
      ).then((response) => {
        return {
          data: response.results,
          pageInfo: {
            hasNextPage: response.pageInfo.hasNextPage,
            hasPreviousPage: response.pageInfo.hasPreviousPage,
          },
        } as GetListResult;
      });
    }),

  update: (resource, params) =>
    withToken(async () => {

      const command = {
        id: params.id.toString(),
        name: params.data.name,
        description: params.data.description,
      };
      return TenantService.tenantUpdate(params.id.toString(), command).then(
        (response) => {
          return TenantService.tenantGet(params.id.toString()).then(
            (response) => {
              return {data: response} as UpdateResult;
            }
          );
        }
      );
    }),

  updateMany: (resource, params) =>
    withToken(async () => {
      return Promise.resolve({ data: [] }) as Promise<UpdateManyResult>;
    }),

  create: (resource, params) =>
    withToken(async () => {
      const command = {
        name: params.data.name,
        description: params.data.description,
        tenantTypeId: params.data.tenantTypeId,
      };
      return TenantService.tenantCreate(command).then((response) => {
        return TenantService.tenantGet(response).then((getResponse) => {
          return {data: getResponse} as CreateResult;
        });
      });
    }),

  delete: (resource, params) =>
    withToken(async () => {
      return TenantService.tenantDelete(params.id.toString()).then(
        (response) => {
          return {data: response} as DeleteResult;
        }
      );
    }),

  deleteMany: (resource, params) =>
    withToken(async () => {
      const deletePromises = params.ids.map((id) =>
        TenantService.tenantDelete(id.toString())
      );
      return Promise.all(deletePromises).then((responses) => {
        return {
          data: responses.map((response) => response),
        } as DeleteManyResult;
      });
    }),
});
