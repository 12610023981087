/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessAreaDto } from '../models/AccessAreaDto';
import type { CreateAccessAreaCommand } from '../models/CreateAccessAreaCommand';
import type { ListQueryResultOfAccessAreaDto } from '../models/ListQueryResultOfAccessAreaDto';
import type { SortDirection } from '../models/SortDirection';
import type { UpdateAccessAreaCommand } from '../models/UpdateAccessAreaCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccessAreaService {

    /**
     * @param id 
     * @returns AccessAreaDto 
     * @throws ApiError
     */
    public static accessAreaGet(
id: string,
): CancelablePromise<AccessAreaDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AccessArea/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static accessAreaUpdate(
id: string,
requestBody: UpdateAccessAreaCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/AccessArea/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static accessAreaDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/AccessArea/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param sortField 
     * @param sortDirection 
     * @param filter 
     * @param activeFilter 
     * @returns ListQueryResultOfAccessAreaDto 
     * @throws ApiError
     */
    public static accessAreaList(
pageNumber?: number,
pageSize?: number,
sortField?: string,
sortDirection?: SortDirection,
filter?: string | null,
activeFilter?: boolean | null,
): CancelablePromise<ListQueryResultOfAccessAreaDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AccessArea',
            query: {
                'Page.Number': pageNumber,
                'Page.Size': pageSize,
                'Sort.Field': sortField,
                'Sort.Direction': sortDirection,
                'Filter': filter,
                'ActiveFilter': activeFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string 
     * @throws ApiError
     */
    public static accessAreaCreate(
requestBody: CreateAccessAreaCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/AccessArea',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns AccessAreaDto 
     * @throws ApiError
     */
    public static accessAreaGetMany(
ids?: Array<string>,
): CancelablePromise<Array<AccessAreaDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AccessArea/GetMany',
            query: {
                'Ids': ids,
            },
        });
    }

}
