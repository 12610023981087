import { OpenAPI } from "./generated";
// We are ts ignoring this because its shared by the container app
// @ts-ignore
import { AuthTokenManager } from "@par/support-portal-auth";

export async function withToken(fn) {
  let token = await AuthTokenManager.getTokenAsync();
  token = token.replace("Bearer ", "");
  OpenAPI.TOKEN = token;
  OpenAPI.BASE = process.env.USER_MANAGEMENT_URL;
  return fn();
}
