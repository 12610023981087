import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { en } from './i18n/en';

const translations = { en };

export const i18nProvider = polyglotI18nProvider(
    (locale: string) => translations[locale as keyof typeof translations] || translations.en,
    resolveBrowserLocale(),
    [
        { locale: 'en', name: 'English' }        
    ],
);
