/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserCommand } from '../models/CreateUserCommand';
import type { ListQueryResultOfUserDto } from '../models/ListQueryResultOfUserDto';
import type { SortDirection } from '../models/SortDirection';
import type { UpdateUserCommand } from '../models/UpdateUserCommand';
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param id 
     * @returns UserDto 
     * @throws ApiError
     */
    public static userGet(
id: string,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static userUpdate(
id: string,
requestBody: UpdateUserCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/User/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static userDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/User/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param sortField 
     * @param sortDirection 
     * @param filter 
     * @param activeFilter 
     * @returns ListQueryResultOfUserDto 
     * @throws ApiError
     */
    public static userList(
pageNumber?: number,
pageSize?: number,
sortField?: string,
sortDirection?: SortDirection,
filter?: string | null,
activeFilter?: boolean | null,
): CancelablePromise<ListQueryResultOfUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User',
            query: {
                'Page.Number': pageNumber,
                'Page.Size': pageSize,
                'Sort.Field': sortField,
                'Sort.Direction': sortDirection,
                'Filter': filter,
                'ActiveFilter': activeFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string 
     * @throws ApiError
     */
    public static userCreate(
requestBody: CreateUserCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns UserDto 
     * @throws ApiError
     */
    public static userGetMany(
ids?: Array<string>,
): CancelablePromise<Array<UserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/GetMany',
            query: {
                'Ids': ids,
            },
        });
    }

}
