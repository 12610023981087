import { ResourceDefinitions } from "react-admin";

export const resourceDefinitions: ResourceDefinitions<any> = {
  concepts: {
    name: "concepts",
    recordRepresentation: "name",
  },
  users: {
    name: "users",
    recordRepresentation: "username",
  },
  accessAreas: {
    name: "accessAreas",
    recordRepresentation: "name",
  },
  tenantTypes: {
    name: "tenantTypes",
    recordRepresentation: "name",
  },
  tenants: {
    name: "tenants",
    recordRepresentation: "name",
  },
  permissions: {
    name: "permissions",
    recordRepresentation: "name",
  },
};
