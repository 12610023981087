import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import IsActiveField from "../Common/IsActiveField";

export const TenantTypeShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" showTime />
      <ReferenceField source="createdBy" reference="users" label="Created by" link="show" />
      <DateField source="modifiedAt" showTime />
      <ReferenceField source="modifiedBy" reference="users" label="Modified by" link="show"  />
      <IsActiveField
        source="isActive"
        label="Active?"
      />
    </SimpleShowLayout>
  </Show>
);
