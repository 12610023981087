import { Admin, Resource } from "react-admin";
import { combinedDataProvider } from "./combinedDataProvider";
import ContactsIcon from "@mui/icons-material/Contacts";
import BusinessIcon from "@mui/icons-material/Business";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HomeIcon from "@mui/icons-material/Home";
import RoofingIcon from "@mui/icons-material/Roofing";
import { i18nProvider } from "./i18nProvider";
import { Box } from "@mui/material";
import {
  ConceptCreate,
  ConceptShow,
  ConceptEdit,
  ConceptList,
} from "./Concepts";
import {
  AccessAreaCreate,
  AccessAreaEdit,
  AccessAreaList,
  AccessAreaShow,
} from "./AccessAreas";
import {
  TenantTypeCreate,
  TenantTypeEdit,
  TenantTypeList,
  TenantTypeShow,
} from "./TenantTypes";
import { TenantCreate, TenantEdit, TenantList, TenantShow } from "./Tenants";
import {
  PermissionCreate,
  PermissionEdit,
  PermissionList,
  PermissionShow,
} from "./Permissions";
import { UserList, UserEdit, UserCreate, UserShow } from "./Users";
import { ParLayout } from "./ParLayout";
import { resourceDefinitions } from "./resourceDefinition";
import { parLightTheme, parDarkTheme } from "./theme";

export const App = () => {
  return (
    <Admin
      dataProvider={combinedDataProvider}
      theme={parLightTheme}
      darkTheme={parDarkTheme}
      defaultTheme="light"
      i18nProvider={i18nProvider}
      layout={ParLayout}
    >
      <Resource
        name="concepts"
        icon={BusinessIcon}
        list={ConceptList}
        edit={ConceptEdit}
        show={ConceptShow}
        create={ConceptCreate}
        recordRepresentation={(record) =>
          record[String(resourceDefinitions["concepts"].recordRepresentation)]
        }
      />
      <Resource
        name="users"
        icon={ContactsIcon}
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        show={UserShow}
        recordRepresentation={(record) =>
          record[String(resourceDefinitions["users"].recordRepresentation)]
        }
      />
      <Resource
        name="accessAreas"
        options={{ label: "Access Areas" }}
        icon={RoofingIcon}
        list={AccessAreaList}
        edit={AccessAreaEdit}
        show={AccessAreaShow}
        create={AccessAreaCreate}
        recordRepresentation={(record) =>
          record[
            String(resourceDefinitions["accessAreas"].recordRepresentation)
          ]
        }
      />
      <Resource
        name="tenantTypes"
        options={{ label: "Tenant Types" }}
        icon={HolidayVillageIcon}
        list={TenantTypeList}
        edit={TenantTypeEdit}
        show={TenantTypeShow}
        create={TenantTypeCreate}
        recordRepresentation={(record) =>
          record[
            String(resourceDefinitions["tenantTypes"].recordRepresentation)
          ]
        }
      />
      <Resource
        name="tenants"
        icon={HomeIcon}
        list={TenantList}
        edit={TenantEdit}
        show={TenantShow}
        create={TenantCreate}
        recordRepresentation={(record) =>
          record[String(resourceDefinitions["tenants"].recordRepresentation)]
        }
      />
      <Resource
        name="permissions"
        icon={DisplaySettingsIcon}
        list={PermissionList}
        edit={PermissionEdit}
        show={PermissionShow}
        create={PermissionCreate}
        recordRepresentation={(record) =>
          record[
            String(resourceDefinitions["permissions"].recordRepresentation)
          ]
        }
      />
    </Admin>
  );
};
