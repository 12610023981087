/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTenantTypeCommand } from '../models/CreateTenantTypeCommand';
import type { ListQueryResultOfTenantTypeDto } from '../models/ListQueryResultOfTenantTypeDto';
import type { SortDirection } from '../models/SortDirection';
import type { TenantTypeDto } from '../models/TenantTypeDto';
import type { UpdateTenantTypeCommand } from '../models/UpdateTenantTypeCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TenantTypeService {

    /**
     * @param id 
     * @returns TenantTypeDto 
     * @throws ApiError
     */
    public static tenantTypeGet(
id: string,
): CancelablePromise<TenantTypeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/TenantType/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static tenantTypeUpdate(
id: string,
requestBody: UpdateTenantTypeCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/TenantType/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static tenantTypeDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/TenantType/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param sortField 
     * @param sortDirection 
     * @param filter 
     * @param activeFilter 
     * @returns ListQueryResultOfTenantTypeDto 
     * @throws ApiError
     */
    public static tenantTypeList(
pageNumber?: number,
pageSize?: number,
sortField?: string,
sortDirection?: SortDirection,
filter?: string | null,
activeFilter?: boolean | null,
): CancelablePromise<ListQueryResultOfTenantTypeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/TenantType',
            query: {
                'Page.Number': pageNumber,
                'Page.Size': pageSize,
                'Sort.Field': sortField,
                'Sort.Direction': sortDirection,
                'Filter': filter,
                'ActiveFilter': activeFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string 
     * @throws ApiError
     */
    public static tenantTypeCreate(
requestBody: CreateTenantTypeCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/TenantType',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns TenantTypeDto 
     * @throws ApiError
     */
    public static tenantTypeGetMany(
ids?: Array<string>,
): CancelablePromise<Array<TenantTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/TenantType/GetMany',
            query: {
                'Ids': ids,
            },
        });
    }

}
