import { Datagrid, DateField, InfiniteList, ReferenceField, SearchInput, TextField } from 'react-admin';
import QuickFilter from "../Common/QuickFilter";
import IsActiveField from "../Common/IsActiveField";

const filters = [
  <SearchInput source="q" placeholder="Search" alwaysOn />,
  <QuickFilter
    source="includeInactive"
    label="Include Inactive"
    defaultValue={true}
  />,
]

export const PermissionList = () => (
    <InfiniteList
        filters={filters}
        sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="description" />
            <ReferenceField
              source="accessAreaId"
              label="Access Area"
              reference="accessAreas"
              link="show" />
            <DateField source="createdAt" label="Created At" />
            <ReferenceField
              source="createdBy"
              label="Created By"
              reference="users"
              link="show" />
            <DateField source="modifiedAt" label="Modified At" />
            <ReferenceField
              source="modifiedBy"
              label="Modified By"
              reference="users"
              link="show" />
            <IsActiveField
              source="isActive"
              label="Active?" />
        </Datagrid>
    </InfiniteList>
);